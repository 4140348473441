/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import {
    DatosBancarios,
    DatosEmpleo,
    DatosEstadoCivil,
    DatosPrestamo,
    DatosProvincias,
    PrestamosCalculosResponse,
    User,
} from '../models/credit-simulator-interface';
import { AgilcredBaseService } from './agilcred-base.service';
import { ClientExistsResponse } from '../models/response/client-exists.response';
import { HttpParams } from '@angular/common/http';
import { environment } from '../enviroments/enviroment';
import { LaundryPreventionResponse } from '../models/response/laundry-prevention.response';
import { OnboardingStartResponse } from '../models/response/onboarding-start.response';
import { DataService } from './data.service';
import { mergeMap } from 'rxjs';
import { ClientLoginResponse } from '../models/response/client-login';
import { ClientInfoResponse } from '../models/response/client-info';
import { LoginResponse } from '../models/response/login.response';

@Injectable({
    providedIn: 'root',
})
export class CreditService extends AgilcredBaseService {
    constructor(private dataService: DataService) {
        super();
    }

    getLoanCalculations(lineaId?: number): Observable<PrestamosCalculosResponse> {
        const params = new HttpParams({ fromObject: { lineaId: lineaId ?? '' } });
        return this.get('prestamos-calculos', lineaId ? params : undefined);
    }

    checkIfClientExists(usuario: string): Observable<ClientExistsResponse> {
        const params = new HttpParams({ fromObject: { usuario } });
        return this.http.get<ClientExistsResponse>(`${environment.apiUrl}/existe-cliente`, { params });
    }

    loginClient(usuario: string, contrasena: string): Observable<ClientLoginResponse> {
        const params = new HttpParams({ fromObject: { usuario, contrasena } });
        return this.http.post<ClientLoginResponse>(`${environment.apiUrl}/login`, params);
    }

    getClientInfo(): Observable<ClientInfoResponse> {
        return this.get(`me`);
    }

    getProvincias(): Observable<DatosProvincias> {
        return this.get('formularios/provincias');
    }

    getMaritalStatus(): Observable<DatosEstadoCivil> {
        return this.get('formularios/estados-civiles');
    }

    getLoanPurposes(): Observable<DatosPrestamo> {
        return this.get('formularios/motivos-prestamos');
    }

    getJobs(): Observable<DatosEmpleo> {
        return this.get('formularios/empleos');
    }

    registerNewUser(user: User): Observable<LoginResponse> {
        return this.post<LoginResponse>('registro', user);
    }

    updatePhone(phone: string): Observable<any> {
        return this.put('celular', { value: phone });
    }

    sendVerificationCodeToUserPhone(): Observable<{ status: 'error' | 'ok'; mensaje: string }> {
        return this.get('sms');
    }

    validateVerificationCodeAndEnableUser(codigo: number): Observable<any> {
        const params = new HttpParams({ fromObject: { codigo } });
        return this.post('sms', '', params);
    }

    requestCreation(request: any): Observable<any> {
        return this.dataService
            .getGiveawayLink$()
            .pipe(mergeMap((link) => this.post('solicitud/crear', { ...request, link: link ?? undefined })));
    }

    //solicitud datos bancarios
    requestDataBank(bankData: DatosBancarios): Observable<any> {
        return this.post(`solicitud/datos-bancarios`, bankData);
    }

    updatePaymentCard(paymentCardId: number): Observable<any> {
        return this.put('solicitud/add-payment-card', { payment_card_id: paymentCardId });
    }

    willProvidePaymentCard(): Observable<any> {
        return this.put('solicitud/will-provide-payment-card', null);
    }
    // inicializa onBoarding widget
    startOnBoardingProvider(): Observable<OnboardingStartResponse> {
        return this.get<OnboardingStartResponse>('onboarding/widget/start');
    }

    //finaliza onboarding widget
    endOnBoardingProvider(id: string): Observable<any> {
        return this.post(`onboarding/widget/end?id=${id}`, '');
    }

    //Genera datos para iniciar el Device Data Collector de Kount.
    startRiskFraudProvider(): Observable<any> {
        return this.get('risk/fraud/start');
    }

    //Recibe un dictamen en función al resultado de risk score: aprobado = "approved" rechazado = "rejected"
    endRiskFraudProvider(session_id: any): Observable<any> {
        return this.post(`risk/fraud/end?session_id=${session_id}`, '');
    }

    //Prevención de lavado
    laundryPrevention(): Observable<LaundryPreventionResponse> {
        return this.post(`risk/prevention`, null);
    }
}
